import { useContext, memo, useRef } from 'react'
import { useRouter } from 'next/router'
import { PreferenceContext } from 'contexts/PreferenceContext'
import { PageWrapper } from 'containers/styles'
import {
  onCloseExpansions,
  redirectToConfirmPage,
  routeToOnboarding,
} from './helpers'
import _has from 'lodash-es/has'
import { useAuth } from 'contexts/AuthContext'
import { useWindowSize } from 'contexts/WindowSizeContext'
import { useAutoHideMobileSearchBar } from 'contexts/MobileSearchBarContext'

import dynamic from 'next/dynamic'
import _isEmpty from 'lodash-es/isEmpty'
import Header from 'components/Header'

import { DECISIONS } from './types'
import { useWithAuthDecision } from './hooks/useWithAuthDecision'
import { removeCookie } from 'helpers/clientSideCookie'
import { LoggedOutHeader } from 'components/LoggedOutHeader'
import { WithAuthContexts } from './WithAuthContexts'
import { MobileMovableWrapper } from 'styles/MobileMovableWrapper'
import { SplashLoader } from 'components/SplashLoader'
import { Modals } from 'components/Modals'
import { AppWrapper } from 'styles/AppWrapper'

const Footer = dynamic(() =>
  import('components/PublicComponents/Footer').then((res) => res.Footer)
)
const OverlayWrap = dynamic(() =>
  import('./styles').then((res) => res.OverlayWrap)
) as unknown as React.FC<any>

const ChatOverlay = dynamic(() => import('components/ChatOverlay'))
const ProgressOverlay = dynamic(() =>
  import('components/ProgressOverlay').then((res) => res.ProgressOverlay)
)

const JobBoardHeader = dynamic(() =>
  import('containers/JobBoardPage/components/JobBoardHeader').then(
    (res) => res.JobBoardHeader
  )
)

export function withAuth(
  Component,
  options: {
    redirectLinkIfVerified?: any
    hideSidebar?: boolean
    LoggedOutComponent?: any
    hideLoggedOutHeaderAndFooter?: boolean
    naiveCheck?: boolean
    forceShowBannerOnLogout?: boolean
  } = {}
) {
  const {
    redirectLinkIfVerified = null,
    hideSidebar = false,
    LoggedOutComponent = null,
    hideLoggedOutHeaderAndFooter = false,
    naiveCheck = false,
    forceShowBannerOnLogout = false,
  } = options

  return memo<any>((props) => {
    const user = props.user
    const router = useRouter()
    const appWrapperRef = useRef(null)

    const { query } = router
    const { preferenceState, updatePreferenceState } =
      useContext(PreferenceContext)
    const { setIsLoggedIn } = useAuth()
    const { isMobile } = useWindowSize()
    const { hideMobileBar } = useAutoHideMobileSearchBar()

    const decision = useWithAuthDecision(user, setIsLoggedIn, naiveCheck)
    const isJobBoardPage = router.pathname.startsWith('/job-board/')
    const isChatPage = router.pathname.startsWith('/chat')

    const renderConfirmedUserComponents = () => {
      if (isJobBoardPage) {
        const { company } = props.data
        return (
          <WithAuthContexts>
            <Modals />
            <MobileMovableWrapper>
              <JobBoardHeader company={company} />
              <AppWrapper scroll noPaddingTop>
                <PageWrapper noPaddingTop>
                  <Component {...props} />
                  <Footer />
                </PageWrapper>
              </AppWrapper>
            </MobileMovableWrapper>
          </WithAuthContexts>
        )
      }

      if (hideSidebar) {
        return (
          <WithAuthContexts>
            <Modals />
            <Component {...props} />
          </WithAuthContexts>
        )
      }

      return (
        <WithAuthContexts>
          <Modals />
          <MobileMovableWrapper
            sidebarExpanded={preferenceState.sidebarExpanded}
            notificationsExpanded={preferenceState.notificationExpanded}
          >
            <Header />
            <AppWrapper
              ref={appWrapperRef}
              onClick={() => onCloseExpansions(updatePreferenceState)}
              scroll
            >
              <Component {...props} appWrapperRef={appWrapperRef} />
            </AppWrapper>
            {!isMobile && !isChatPage && !isJobBoardPage && (
              <OverlayWrap>
                <ProgressOverlay />
                <ChatOverlay />
              </OverlayWrap>
            )}
          </MobileMovableWrapper>
        </WithAuthContexts>
      )
    }

    if (decision === DECISIONS.LOADING) {
      return <SplashLoader />
    }

    if (decision === DECISIONS.UNCONFIRMED_USER) {
      redirectToConfirmPage(user?.email, router)
      return <SplashLoader />
    }

    if (decision === DECISIONS.REDIRECT_TO_ONBOARDING) {
      if (router.pathname === '/confirm-success') {
        setTimeout(() => routeToOnboarding(router), 3000)
      } else routeToOnboarding(router)
      return renderConfirmedUserComponents()
    }

    if (decision === DECISIONS.INVALID_USER) {
      removeCookie('accessToken')
      removeCookie('clientId')
      removeCookie('userId')

      if (!LoggedOutComponent) {
        localStorage.removeItem('userFullName')
        router.push('/login?unauthorized=true')
        return <SplashLoader />
      }

      if (hideLoggedOutHeaderAndFooter) {
        return (
          <>
            <Modals />
            <LoggedOutComponent {...props} />
          </>
        )
      }

      if (isJobBoardPage) {
        const { company } = props.data

        return (
          <MobileMovableWrapper>
            <Modals />
            <JobBoardHeader company={company} />
            <AppWrapper scroll noPaddingTop>
              <PageWrapper noPaddingTop>
                <LoggedOutComponent {...props} />
                <Footer />
              </PageWrapper>
            </AppWrapper>
          </MobileMovableWrapper>
        )
      }

      return (
        <MobileMovableWrapper>
          <Modals />
          <LoggedOutHeader forceShowBannerOnLogout={forceShowBannerOnLogout} />
          <AppWrapper
            ref={appWrapperRef}
            scroll
            loggedOut
            noPaddingTop={hideMobileBar}
          >
            <PageWrapper noPaddingTop>
              <LoggedOutComponent {...props} appWrapperRef={appWrapperRef} />
              <Footer />
            </PageWrapper>
          </AppWrapper>
        </MobileMovableWrapper>
      )
    }

    // if CONFIRMED_USER
    if (redirectLinkIfVerified) {
      router.replace({
        pathname: redirectLinkIfVerified,
        query,
      })
      return null
    }
    //NOTE: block confirm-success and invalid-confirmation-link
    //      page access for confirmed users
    if (
      router.pathname === '/confirm-success' ||
      router.pathname === '/invalid-confirmation-link'
    ) {
      router.replace('/dashboard')
      return null
    }
    return renderConfirmedUserComponents()
  })
}
