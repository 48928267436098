import { AnimatedTitle } from 'components/AnimatedText'
import { Button, BUTTON_VARIANTS } from 'components/Button'
import { Fade } from 'components/Fade'
import { Text } from 'components/Text'
import { useWindowSize } from 'contexts/WindowSizeContext'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Wrapper } from 'styles/styleSystem/wrapper'

export const CTA = () => {
  const { isMobile } = useWindowSize()
  const router = useRouter()

  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      spacing={10}
      fullWidth
      mt={!isMobile && 200}
    >
      <Wrapper
        display="flex"
        justifyContent="center"
        flexDirection="column"
        spacing={20}
        mb={!isMobile && 40}
        maxWidth={800}
      >
        <Fade noFadeOut fadeKey="CTA">
          <Wrapper
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <AnimatedTitle
              text="Merit-Based,"
              size={isMobile ? '13xl' : 'HERO'}
              color="primary"
              weight="bolder"
              align="center"
              fontFamily="Rubik"
              delay={0.5}
            />
            <AnimatedTitle
              text="Bias Free Hiring"
              size={isMobile ? '13xl' : 'HERO'}
              color="primary"
              weight="bolder"
              align="center"
              fontFamily="Rubik"
              delay={0.5}
            />
            <Fade noFadeOut delay={1.5} fadeOutVerticalDistance={0}>
              <Wrapper mt={30}>
                <Text
                  size="3xl"
                  weight="light"
                  color="secondary"
                  align="center"
                  leading="relaxed"
                >
                  Fintros uses patent-pending Artificial Intelligence to
                  instantly anonymize resumes and hiring documents so you can
                  discreetly and anonymously apply to new job opportunities.
                </Text>
              </Wrapper>
            </Fade>
          </Wrapper>
        </Fade>
      </Wrapper>
      <Wrapper width={300}>
        <Fade noFadeOut delay={2} fadeOutVerticalDistance={0}>
          <Button
            onClick={() => router.push('/signup')}
            variant={BUTTON_VARIANTS.primary}
            label="Get Started"
            size="lg"
            fullWidth
          />
        </Fade>
      </Wrapper>
    </Wrapper>
  )
}
