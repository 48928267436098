export const HERO_JOBS = [
  {
    title: 'Investment Manager',
    company: 'Royal Bank of Canada',
    banner:
      'http://static1.squarespace.com/static/5e00574d0add4d0f3b2c140e/5eb0fe0d47d9544542482cc6/5eefb0c32814402a19d51ee9/1593379606692/rbc+banner.jpg?format=1500w',
    logo: 'http://static1.squarespace.com/static/5e00574d0add4d0f3b2c140e/5eb0fe0d47d9544542482cc6/5eefb0c32814402a19d51ee9/1593379606692/rbc+banner.jpg?format=1500w',
    salary: '$140k - $180k',
    industry: 'Banking',
  },
  {
    title: 'Junior Accountant',
    company: 'Barclays',
    banner:
      'https://www.princessroyaltrainingawards.com/wp-content/uploads/2017/08/Logos-510x288-Barclays.jpg',
    logo: 'https://www.butterwick.org.uk/wp-content/uploads/2019/11/Barclays-Bank-logo.png',
    salary: '$70k - $80k',
    industry: 'Accounting',
  },
  {
    title: 'Financial Advisor',
    company: 'Goldman Sachs',
    banner: 'https://www.beqom.com/hubfs/Corporate%20News/goldman-banner-2.jpg',
    logo: 'https://www.goldmansachs.com/media-relations/press-releases/2021/multimedia/gs-aws-LI-share.png',
    salary: '$90k - $110k',
    industry: 'Banking',
  },
  {
    title: 'Equity Trader',
    company: 'TD Bank',
    banner: 'https://stories.td.com/volumes/default/article/amcblogo.jpg',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Toronto-Dominion_Bank_logo.svg/1200px-Toronto-Dominion_Bank_logo.svg.png',
    salary: '$110k - $120k',
    industry: 'Banking',
  },
  {
    title: 'Investment Banker',
    company: 'JP Morgan & Chase',
    banner:
      'https://brokerchalk.com/wp-content/uploads/2021/05/jpmorgan-chase-picture-id880121294.jpg',
    logo: 'https://media2.vault.com/14343503/210909_jp-morgan_logo.jpg',
    salary: '$150k - $170k',
    industry: 'Banking',
  },
] as const
