import { Carousel } from 'components/Carousel'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { HeroJobCard } from '../HeroJobCard'
import { HERO_JOBS } from './constants'
import { Fade } from 'components/Fade'

export const JobCarousel = () => {
  return (
    <Wrapper maxWidth={1200} height="100%">
      <Carousel
        autoplay
        slidesPerView={3}
        hideScrollbarBottomBorder
        centerSlides
        scrollbar={false}
        mouseWheel={false}
      >
        {HERO_JOBS.map((job, index) => (
          <Fade key={`landing-carousel-job-${index}`} delay={0.4}>
            <HeroJobCard job={job} />
          </Fade>
        ))}
      </Carousel>
    </Wrapper>
  )
}
