import styled from 'styled-components'
import { PHONE_BREAKPOINT, TABLET_BREAKPOINT } from 'styles/breakpoints'

export const ProductImageWrapper = styled.div`
  margin: 40px 0;
  width: 100%;

  > * {
    -webkit-filter: drop-shadow(0px -30px 30px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0px -30px 30px rgba(0, 0, 0, 0.1));
  }
`

export const LandingGradientWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100vw - 80px);
  background: ${({ theme }) =>
    `linear-gradient(to bottom, transparent, ${theme.background.tertiary}90)`};
  border-radius: 20px;
  padding: 0 100px;
  gap: 200px;

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 40px;
  }

  @media screen and (max-width: ${PHONE_BREAKPOINT}px) {
    padding: 20px;
  }
`
