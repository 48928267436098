import styled, { css } from 'styled-components'
import { Text } from 'components/Text'

export const TagContainer = styled.div<{
  color?: string
  height?: string
}>`
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${({ theme, color }) =>
    color ? theme[color] + '33' : theme.background.tertiary};
  display: flex;
  align-items: center;
  height: ${({ height }) => (height ? height : '30px')};
  width: fit-content;
`

export const TagText = styled(Text)<{
  color?: string
}>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: ${({ theme, color }) => (color ? theme[color] : theme.secondary)};
`
