import { CompanyAnonBadge } from 'components/CompanyAnonBadge'
import { Image } from 'components/Image'
import { JobCardTag } from 'components/JobCardTag'
import { Text } from 'components/Text'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { HeroJobCardLogoWrapper, HeroJobCardWrapper } from './styles'
import { BUTTON_VARIANTS, Button } from 'components/Button'
import { Divider } from 'components/Divider'
import { useRouter } from 'next/router'

interface HeroJobCardProps {
  job: {
    title: string
    company: string
    banner: string
    logo: string
    industry: string
    salary: string
  }
}

export const HeroJobCard: React.FC<HeroJobCardProps> = ({ job }) => {
  const { title, company, banner, logo, industry, salary } = job
  const router = useRouter()

  return (
    <HeroJobCardWrapper>
      <Image
        src={banner}
        fullWidth
        height={200}
        nextImageSizes="(max-width: 300px) 300px, 300px"
        nextImagePriority
        loading="eager"
      />
      <Wrapper p={30} display="flex" flexDirection="column">
        <HeroJobCardLogoWrapper>
          <Image
            src={logo}
            height={80}
            width={80}
            nextImageSizes="(max-width: 80px) 80px, 80px"
          />
        </HeroJobCardLogoWrapper>
        <Text size="6xl" weight="bolder" leading="loose" lines={1}>
          {title}
        </Text>
        <Wrapper display="flex" alignItems="center" spacing={10} horizontal>
          <Text size="3xl" weight="light" color="secondary">
            {company}
          </Text>
          <CompanyAnonBadge companyName={company} />
        </Wrapper>
        <Wrapper mt={20} display="flex" spacing={10} horizontal>
          <JobCardTag type="basic" labelData={salary} />
          <JobCardTag type="basic" labelData={industry} />
        </Wrapper>
        <Divider margin={20} />
        <Button
          variant={BUTTON_VARIANTS.secondary}
          size="lg"
          label="Anonymously Apply &trade;"
          onClick={() => router.push('/signup')}
        />
      </Wrapper>
    </HeroJobCardWrapper>
  )
}
