import { Tooltip } from 'antd'
import { JobCardTag } from 'components/JobCardTag'
import { HiBadgeCheck } from 'react-icons/hi'
import { useTheme } from 'styled-components'

export interface CompanyAnonBadgeProps {
  companyName?: string
  margin?: boolean
}

export const CompanyAnonBadge: React.FC<CompanyAnonBadgeProps> = ({
  companyName = null,
  margin = false,
}) => {
  const theme = useTheme()
  const title = companyName || 'This company'

  return (
    <Tooltip title={`${title} allows anonymous applications`}>
      <HiBadgeCheck
        color={theme.highlight}
        size={margin && 18}
        style={{ marginRight: margin ? '10px' : '0px' }}
      />
    </Tooltip>
  )
}
