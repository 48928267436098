import { Wrapper } from 'styles/styleSystem/wrapper'
import { TagContainer, TagText } from './styles'
import { formatSalaryRange } from '../../helpers/salary'
import { Tooltip } from 'antd'
import { MdBusiness, MdCheckCircle, MdPerson } from 'react-icons/md'
import { useTheme } from 'styled-components'
import { formatDate } from 'helpers/time'
import { formatEmployeeCount } from 'helpers/string'
import { Icon, ICON_VARIANTS } from 'components/Icon'
import { memo, useContext } from 'react'
import _isString from 'lodash-es/isString'
import _isObject from 'lodash-es/isEmpty'
import { AppliedJobsContext } from 'contexts/AppliedJobsContext/context'

export interface JobCardTagProps {
  type?: any
  color?: any
  height?: string
  useTooltip?: boolean
  tooltipMessage?: string
  labelData?:
    | string
    | number
    | boolean
    | { from: number; to: number }
    | { achievedCount: number; totalCount: number }
  applyDate?: Date
}

export const JobCardTag: React.FC<JobCardTagProps> = memo(
  ({ type, labelData, color, height, useTooltip = true, tooltipMessage }) => {
    const theme = useTheme()

    const types = {
      basic: {
        renderLabel: () => labelData,
      },
      remote: {
        renderLabel: () => 'Remote',
      },
      applicants: {
        renderLabel: () => `${labelData} Applicants`,
      },
      totalQuestions: {
        renderLabel: () => `${labelData} Questions`,
      },
      employees: {
        renderLabel: () => `${labelData}+ Employees`,
      },
      locations: {
        renderLabel: () => `${labelData} Locations`,
      },
      jobs: {
        renderLabel: () => `${labelData} Jobs`,
      },
      draft: {
        renderLabel: () => 'Draft Started',
      },
      employee_count: {
        renderLabel: () => (
          <Wrapper display="flex" alignItems="center">
            <MdPerson />
            <Wrapper ml={1} display="flex" alignItems="center">
              {formatEmployeeCount(labelData)}
            </Wrapper>
          </Wrapper>
        ),
      },
      applied: {
        renderLabel: () => (
          <Tooltip
            title={`Applied ${
              _isString(labelData) ? formatDate(labelData) : labelData
            }`}
          >
            <Wrapper display="flex" alignItems="center">
              <MdCheckCircle color={theme[color]} />
              <Wrapper ml={1} display="flex" alignItems="center">
                Applied
              </Wrapper>
            </Wrapper>
          </Tooltip>
        ),
      },
      applied_alt_tag: {
        renderLabel: () => (
          <Tooltip
            title={`Applied ${
              _isString(labelData) ? formatDate(labelData) : labelData
            }`}
          >
            <Wrapper display="flex" alignItems="center">
              <MdCheckCircle color={theme[color]} />
              <Wrapper ml={1} display="flex" alignItems="center">
                Applied {_isString(labelData) && formatDate(labelData)}
              </Wrapper>
            </Wrapper>
          </Tooltip>
        ),
      },
      saved_alt_tag: {
        renderLabel: () => (
          <Tooltip
            title={`Saved ${
              _isString(labelData) ? formatDate(labelData) : labelData
            }`}
          >
            <Wrapper display="flex" alignItems="center">
              <MdCheckCircle color={theme[color]} />
              <Wrapper ml={1} display="flex" alignItems="center">
                Saved {_isString(labelData) && formatDate(labelData)}
              </Wrapper>
            </Wrapper>
          </Tooltip>
        ),
      },
      apply_anonymously: {
        renderLabel: () => (
          <Tooltip
            title={tooltipMessage || 'This job can be applied to anonymously'}
          >
            <Wrapper display="flex" alignItems="center">
              <Icon
                variant={ICON_VARIANTS.logo}
                type="fintros"
                width={14}
                height={16}
              />
              <Wrapper ml={1} display="flex" alignItems="center">
                {labelData}&trade;
              </Wrapper>
            </Wrapper>
          </Tooltip>
        ),
      },
      applied_anonymously: {
        renderLabel: () => (
          <Tooltip
            title={'Company only has access to anonymous resume Applied'}
          >
            <Wrapper display="flex" alignItems="center">
              <Icon
                variant={ICON_VARIANTS.logo}
                type="fintros"
                width={14}
                height={16}
              />
              <Wrapper ml={1} display="flex" alignItems="center">
                Anonymously Applied&trade;
              </Wrapper>
            </Wrapper>
          </Tooltip>
        ),
      },
      industry: {
        renderLabel: () => (
          <Tooltip title={labelData as any}>
            <MdBusiness />
          </Tooltip>
        ),
      },
      industry_with_text: {
        renderLabel: () => (
          <Wrapper display="flex" alignItems="center">
            <MdBusiness />
            <Wrapper ml={1} display="flex" alignItems="center">
              {labelData}
            </Wrapper>
          </Wrapper>
        ),
      },
      expired: {
        renderLabel: () =>
          useTooltip ? (
            <Tooltip title="Job is expired">Expired</Tooltip>
          ) : (
            'Expired'
          ),
      },
      salary: {
        renderLabel: () =>
          _isObject(labelData) ? formatSalaryRange(labelData) : labelData,
      },
      topPercentage: {
        renderLabel: () => `Top ${labelData}%`,
      },
      completed: {
        renderLabel: () => (
          <Wrapper display="flex" alignItems="center">
            <MdCheckCircle color={theme[color]} />
            <Wrapper ml={1} display="flex" alignItems="center">
              Completed
            </Wrapper>
          </Wrapper>
        ),
      },
      time: {
        renderLabel: () => (
          <span>
            <>{labelData}&nbsp;min</>
          </span>
        ),
      },
    }
    return (
      <TagContainer color={color} height={height}>
        <Wrapper display="flex" alignItems="center">
          <TagText size="lg" weight="light" color={color}>
            {types[type]?.renderLabel() || ''}
          </TagText>
        </Wrapper>
      </TagContainer>
    )
  }
)
