import { Text, TextProps } from 'components/Text'
import { motion, useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'

const wordAnimation = {
  hidden: {},
  visible: {},
}

const characterAnimation = {
  hidden: {
    opacity: 0,
    y: `0.25em`,
  },
  visible: {
    opacity: 1,
    y: `0em`,
    transition: {
      duration: 2,
      ease: [0.2, 0.65, 0.3, 0.9],
    },
  },
}

const Title = styled(Text)<{
  $gradient?: boolean
}>`
  ${({ $gradient }) =>
    $gradient &&
    css`
      background: linear-gradient(95.99deg, #6fbaff 41.4%, #0086ff 72.31%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    `};
`

const Word = styled(motion.span)`
  display: inline-block;
  margin-right: 0.25em;
  white-space: nowrap;
`

const Character = styled(motion.span)`
  display: inline-block;
  margin-right: -0.05em;
`

interface AnimatedTitleProps extends TextProps {
  text: string
  gradient?: boolean
  delay?: number
  align?: string
  fontFamily?: string
}

export const AnimatedTitle: React.FC<AnimatedTitleProps> = ({
  text,
  size,
  weight,
  color,
  gradient,
  delay = 0,
  align,
  fontFamily = 'Lato',
}) => {
  const ctrls = useAnimation()

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      ctrls.start('visible')
    }
    if (!inView) {
      ctrls.start('hidden')
    }
  }, [ctrls, inView])

  return (
    <Title
      size={size}
      weight={weight}
      color={color}
      $gradient={gradient}
      align={align}
      fontFamily={fontFamily}
      leading="tight"
    >
      {text.split(' ').map((word, index) => {
        return (
          <Word
            key={`animated-word-${index}`}
            ref={ref}
            aria-hidden="true"
            initial="hidden"
            animate={ctrls}
            variants={wordAnimation}
            viewport={{ once: false }}
            transition={{
              delayChildren: index * 0.5 + delay,
              staggerChildren: 0.1,
            }}
          >
            {word.split('').map((character, index) => {
              return (
                <Character
                  key={`animated-character-${index}`}
                  aria-hidden="true"
                  variants={characterAnimation}
                >
                  {character}
                </Character>
              )
            })}
          </Word>
        )
      })}
    </Title>
  )
}
