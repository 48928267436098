import { ResumeExample } from 'components/ResumeExample'
import { ResumeExampleScorecard } from 'components/ResumeExample/components/ResumeExampleScorecard'
import { EXAMPLE_RESUMES } from 'containers/ResumeExamplePage/constants'
import { useWindowSize } from 'contexts/WindowSizeContext'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { useState } from 'react'
import { Wrapper } from 'styles/styleSystem/wrapper'

export const LandingPageResumeExample: React.FC<{
  appWrapperRef: React.MutableRefObject<HTMLDivElement>
}> = ({ appWrapperRef }) => {
  // Scroll tracking
  const { isMobile } = useWindowSize()
  const [currentScrollY, setCurrentScrollY] = useState(0)

  const { scrollY } = useScroll({
    container: appWrapperRef,
    layoutEffect: false,
  })

  useMotionValueEvent(scrollY, 'change', (latest) => {
    setCurrentScrollY(latest)
  })

  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      alignItems="center"
      spacing={20}
      my={isMobile ? 100 : 300}
      fullWidth
    >
      <ResumeExample
        currentResume={EXAMPLE_RESUMES[0]}
        currentScrollY={currentScrollY}
      />
      <ResumeExampleScorecard />
    </Wrapper>
  )
}
