import { useWindowSize } from 'contexts/WindowSizeContext'
import dynamic from 'next/dynamic'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { CTA } from './components/CTA'
import { JobCarousel } from './components/JobCarousel'

const AnonDemo = dynamic(() =>
  import('./components/AnonDemo').then((res) => res.AnonDemo)
)

export const Hero = () => {
  const { isMobile } = useWindowSize()
  return (
    <Wrapper
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="calc(100vh - 70px)"
      mt={!isMobile && 70}
      width="100vw"
      maxWidth="1300px"
      spacing={40}
    >
      <CTA />
      {!isMobile && (
        <Wrapper flex={1} display="flex" alignItems="center" p={60}>
          {/* <AnonDemo /> */}
          <JobCarousel />
        </Wrapper>
      )}
    </Wrapper>
  )
}
