import { PreferenceState } from 'contexts/PreferenceContext'
import { NextRouter } from 'next/router'
import { Dispatch, SetStateAction } from 'react'

export const redirectToConfirmPage = async (
  email: string,
  router: NextRouter
) => {
  await fetch('/api/request-confirm-account', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      confirm_success_url: 'confirm-success',
    }),
  })
  return router.push('/accountConfirm')
}

export const routeToOnboarding = async (router: NextRouter) => {
  await router.push('/onboarding' + window.location.search)
}

export const onCloseExpansions = (
  updatePreferenceState: Dispatch<SetStateAction<PreferenceState>>
) => {
  updatePreferenceState((prev) => ({
    ...prev,
    sidebarExpanded: false,
    notificationExpanded: false,
  }))
}
