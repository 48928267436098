import dynamic from 'next/dynamic'
import React from 'react'

const SavedJobsContextProvider = dynamic(() =>
  import('contexts/SavedJobsContext').then(
    (res) => res.SavedJobsContextProvider
  )
)
const AppliedJobsContextProvider = dynamic(() =>
  import('contexts/AppliedJobsContext').then(
    (res) => res.AppliedJobsContextProvider
  )
)
const ResumeContextProvider = dynamic(() =>
  import('contexts/ResumeContext').then((res) => res.ResumeContextProvider)
)
const ChatsProvider = dynamic(() =>
  import('contexts/ChatContext').then((res) => res.ChatsProvider)
)
const UserProfileContextProvider = dynamic(() =>
  import('contexts/UserProfileContext').then(
    (res) => res.UserProfileContextProvider
  )
)

export const WithAuthContexts: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <UserProfileContextProvider>
    <SavedJobsContextProvider>
      <AppliedJobsContextProvider>
        <ResumeContextProvider>
          <ChatsProvider>{children}</ChatsProvider>
        </ResumeContextProvider>
      </AppliedJobsContextProvider>
    </SavedJobsContextProvider>
  </UserProfileContextProvider>
)
