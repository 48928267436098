import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DECISIONS } from '../types'
import _omit from 'lodash-es/omit'
import { StringParam, useQueryParams } from 'next-query-params'
import { getCookie } from 'helpers/clientSideCookie'

export const useWithAuthDecision = (
  user,
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>,
  naiveCheck: boolean
) => {
  const [decision, setDecision] = useState<DECISIONS>(DECISIONS.LOADING)
  const router = useRouter()
  const [{ client_id, token, uid }, setQueryParams] = useQueryParams({
    uid: StringParam,
    client_id: StringParam,
    token: StringParam,
  })

  useEffect(() => {
    async function verifyUser() {
      if (user && (client_id || token || uid)) {
        setQueryParams(
          { client_id: undefined, token: undefined, uid: undefined },
          'replaceIn'
        )
      }

      let naiveCheckPassed = false

      if (naiveCheck) {
        const accessToken = getCookie('accessToken')
        const client = getCookie('clientId')
        const uid = getCookie('userId')
        if (accessToken && client && uid) naiveCheckPassed = true
      }

      const isLoggedIn = (naiveCheck && naiveCheckPassed) || !!user

      setIsLoggedIn(isLoggedIn)

      if (!isLoggedIn) return setDecision(DECISIONS.INVALID_USER)

      // if they have not confirmed their email
      const isConfirmed = user?.confirmed || (naiveCheck && naiveCheckPassed)
      if (!isConfirmed) {
        return setDecision(DECISIONS.UNCONFIRMED_USER)
      }
      const isOnboarded = !!user
        ? user.onboarding_completed_at
        : naiveCheck && naiveCheckPassed

      const isAtOnboardingPage = router.pathname.includes('onboarding')

      if (!isOnboarded && !isAtOnboardingPage) {
        return setDecision(DECISIONS.REDIRECT_TO_ONBOARDING)
      }

      setDecision(DECISIONS.CONFIRMED_USER)
    }
    verifyUser()
  }, [user])

  return decision
}
