import { GetServerSidePropsContext, PreviewData } from 'next'
import { ParsedUrlQuery } from 'querystring'

export enum DECISIONS {
  UNCONFIRMED_USER = 'UNCONFIRMED_USER',
  CONFIRMED_USER = 'CONFIRMED_USER',
  INVALID_USER = 'INVALID_USER',
  REDIRECT_TO_ONBOARDING = 'REDIRECT_TO_ONBOARDING',
  LOADING = 'LOADING',
}

export type GetServerSidePropsFuncType = (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => any
