import { Image } from 'components/Image'
import styled from 'styled-components'

export const HeroJobCardWrapper = styled.div`
  background: ${({ theme }) => theme.background.secondary};
  border: 1px solid ${({ theme }) => theme.border};
  overflow: hidden;
  border-radius: 5px;
`

export const HeroJobCardLogoWrapper = styled.div`
  z-index: 100;
  border: 2px solid ${({ theme }) => theme.background.secondary};
  border-radius: 5px;
  overflow: hidden;
  margin-top: -80px;
  width: fit-content;
`
