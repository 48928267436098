import {
  ANNUAL_SALARY,
  DAY_SALARY,
  HOUR_SALARY,
  MONTHLY_SALARY,
  WEEKLY_SALARY,
} from 'containers/SalariesPage/constants'

export const formatSalaryRange = (salary) => {
  const min = salary?.from
  const max = salary?.to
  let minSal
  let maxSal

  let denomination = ''
  if (min > 1000000) minSal = Math.floor(min / 1000000)
  else if (min > 1000) minSal = Math.floor(min / 1000)
  else minSal = min
  if (max > 1000000) {
    maxSal = Math.floor(max / 1000000)
    denomination = 'M'
  } else if (max > 1000) {
    maxSal = Math.floor(max / 1000)
    denomination = 'K'
  } else maxSal = max
  return `$${minSal}-$${maxSal}${denomination}`
}

export const getWage = {
  [ANNUAL_SALARY](salary) {
    return parseFloat(salary)
  },
  [MONTHLY_SALARY](salary) {
    return Math.round(parseFloat(salary) / 12)
  },
  [WEEKLY_SALARY](salary) {
    return Math.round(parseFloat(salary) / 50)
  },
  [DAY_SALARY](salary) {
    return Math.round(Math.round(parseFloat(salary) / 50) / 5)
  },
  [HOUR_SALARY](salary) {
    return (parseFloat(salary) / 2080).toFixed(2)
  },
}

export const formatWage = (wage: number | string): string => {
  const parts = wage.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const formatSalaryToString = (salary, salaryOption) => {
  const wage = getWage[salaryOption || ANNUAL_SALARY](salary)

  // Render string with commas separating thousands
  return formatWage(wage)
}
