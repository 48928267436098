import { useWindowSize } from 'contexts/WindowSizeContext'
import React from 'react'
import { useQuery } from '@apollo/client'
import { useAuth } from 'contexts/AuthContext'
import { GET_NOTIFICATION_COUNT } from 'graphql/queries/notifications'
import dynamic from 'next/dynamic'

const HeaderMobile = dynamic(() =>
  import('./HeaderMobile').then((res) => res.HeaderMobile)
)
const HeaderContent = dynamic(() =>
  import('./HeaderContent').then((res) => res.HeaderContent)
)
const HeaderStyles = dynamic(() =>
  import('./styles').then((res) => res.HeaderStyles)
) as unknown as React.FC<any>

const Header: React.FC = () => {
  const { isMobile } = useWindowSize()
  const { isLoggedIn } = useAuth()
  const { data } = useQuery(GET_NOTIFICATION_COUNT, {
    pollInterval: 5000,
    skip: !isLoggedIn,
    fetchPolicy: 'network-only',
  })

  const notificationCount =
    data?.currentUserData[0]?.unreadNotificationCount || 0

  return (
    <>
      {/* needs to be <= or we have a 1px gap where there will be no header at all */}
      {isMobile ? (
        <HeaderMobile notificationCount={notificationCount} />
      ) : (
        <HeaderStyles>
          <HeaderContent notificationCount={notificationCount} />
        </HeaderStyles>
      )}
    </>
  )
}

export default React.memo(Header)
