import { gql } from '@apollo/client'

export const GET_NOTIFICATION_COUNT = gql`
  query {
    currentUserData {
      id
      unreadNotificationCount
    }
  }
`

export const GET_NOTIFICATIONS_BY_CATEGORY = gql`
  query ($category: NotificationCategory, $page: Int, $notifsPerPage: Int) {
    currentUserData {
      unreadNotificationCount # count of total unread notifications
      id
      notifications(
        category: $category
        page: $page
        notifsPerPage: $notifsPerPage
      ) {
        id
        subject
        body
        category
        imageUrl # if empty, can we have defaults on the front-end
        viewed
        viewedAt
        notificationTime
        redirectSlug
        hasNextPage
      }
    }
  }
`

export const MARK_ALL_NOTIFICATIONS_READ = gql`
  mutation {
    notificationMarkViewedAll {
      errors
      status
    }
  }
`
