import moment from 'moment'

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1M',
    MM: '%dM',
    y: '1Y',
    yy: '%dY',
  },
})

export const timeFromNow = (date) => {
  return moment(date).fromNow()
}

export const formatDate = (date) => {
  return moment(date).format('MMM DD, YYYY')
}
