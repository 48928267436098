import { Button, BUTTON_VARIANTS } from 'components/Button'
import { Fade } from 'components/Fade'
import { Text, TEXT_SEMANTIC_VARIANTS } from 'components/Text'
import { useWindowSize } from 'contexts/WindowSizeContext'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTheme } from 'styled-components'
import { Wrapper } from 'styles/styleSystem/wrapper'

const Globe = dynamic(
  () => import('components/Globe').then((res) => res.Globe),
  { ssr: false }
)

export const Locations: React.FC<{ locationMarkers: any[] }> = ({
  locationMarkers,
}) => {
  const { isPhone, isLaptop, isDesktop } = useWindowSize()
  const theme = useTheme()
  const isLight = theme.is_light_mode
  const router = useRouter()

  const getSpacing = () => {
    if (isLaptop) return 50
    if (isDesktop) return 100

    return 150
  }

  return (
    <Wrapper
      bg={isLight ? 'primary' : 'black'}
      width="100vw"
      display="flex"
      alignItems="center"
      spacing={getSpacing()}
      flexDirection={isLaptop ? 'column' : 'row'}
      horizontal={!isLaptop}
      py={200}
      mt={60}
    >
      <Wrapper
        flex={isLaptop ? 1 : 2}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        flexDirection="column"
      >
        <Wrapper
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems={isLaptop && 'center'}
          spacing={20}
        >
          <Fade noFadeOut>
            <Text
              size={isLaptop ? '13xl' : 'HERO'}
              weight="bolder"
              fontFamily="Rubik"
              leading="tight"
              noWrap
            >
              Find a job <br></br>anywhere.
            </Text>
          </Fade>
          <Fade delay={0.2} noFadeOut>
            <Text
              size="2xl"
              weight="light"
              leading="loose"
              color="secondary"
              align={isLaptop && 'center'}
            >
              Fintros has job postings around the world to <br />
              best fit your future plans <br />
              <Text
                as={TEXT_SEMANTIC_VARIANTS.span}
                italics
                size="lg"
                color="secondary"
              >
                Only showing Top 500 Fintros Cities
              </Text>
            </Text>
          </Fade>
          <Fade noFadeOut delay={0.6}>
            <Wrapper mt={40} width="200px">
              <Button
                onClick={() => router.push('/search/jobs')}
                fullWidth
                variant={BUTTON_VARIANTS.primary}
                label="Start searching"
                size="lg"
              />
            </Wrapper>
          </Fade>
        </Wrapper>
      </Wrapper>
      <Wrapper
        flex={isPhone ? 1 : 3}
        display="flex"
        justifyContent="center"
        height="100%"
        maxHeight="100vh"
        width={isLaptop ? '500px' : '100%'}
      >
        <Globe locationMarkers={locationMarkers} />
      </Wrapper>
    </Wrapper>
  )
}
