import { PageContent, PageContentWrapper } from 'containers/styles'
import { useWindowSize } from 'contexts/WindowSizeContext'
import dynamic from 'next/dynamic'
import { useInView } from 'react-intersection-observer'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { Hero } from './components/Hero'
import { Locations } from './components/Locations'
import { LandingGradientWrapper } from './styles'
import Head from 'next/head'
import { LandingPageResumeExample } from './components/LandingPageResumeExample'

const MoreFeatures = dynamic(() =>
  import('./components/MoreFeatures').then((res) => res.MoreFeatures)
)
const Features = dynamic(() =>
  import('./components/Features').then((res) => res.Features)
)
const CTA = dynamic(() => import('./components/CTA').then((res) => res.CTA))

const Values = dynamic(() =>
  import('./components/Values').then((res) => res.Values)
)
const FAQ = dynamic(() => import('./components/FAQ').then((res) => res.FAQ))
const CompanyGrids = dynamic(() =>
  import('./components/CompanyGrids').then((res) => res.CompanyGrids)
)
const TestimonialGrid = dynamic(() =>
  import('./components/TestimonialGrid').then((res) => res.TestimonialGrid)
)

export const LandingPage = ({
  data: { locationMarkers, faqs, companies, testimonials },
  appWrapperRef,
}) => {
  const { isPhone } = useWindowSize()
  const { ref: featureRef, inView: featureInView } = useInView({
    triggerOnce: true,
  })
  const { ref: moreFeatureRef, inView: moreFeatureInView } = useInView({
    triggerOnce: true,
  })
  const { ref: companyAndTestimonialRef, inView: companyAndTestimonialInView } =
    useInView({ triggerOnce: true })

  const { ref: remainingRef, inView: remainingInView } = useInView({
    triggerOnce: true,
  })

  return (
    <Wrapper fullWidth>
      <Head>
        <title>Fintros | Merit-Based, Bias-Free Anonymous Hiring</title>
      </Head>
      <PageContent noScroll>
        <PageContentWrapper>
          <Wrapper
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            fullWidth
          >
            <Hero />
            <LandingPageResumeExample appWrapperRef={appWrapperRef} />
            <LandingGradientWrapper ref={featureRef}>
              {featureInView && <Features />}
            </LandingGradientWrapper>
            <div ref={moreFeatureRef}>
              {moreFeatureInView && <MoreFeatures />}
            </div>
            <LandingGradientWrapper ref={companyAndTestimonialRef}>
              {companyAndTestimonialInView && (
                <>
                  <CompanyGrids companies={companies} />
                  <TestimonialGrid testimonials={testimonials} />
                </>
              )}
            </LandingGradientWrapper>
            {/* Load Globe a bit earlier to prevent lag */}
            {featureInView && <Locations locationMarkers={locationMarkers} />}
            <LandingGradientWrapper minHeight={500} ref={remainingRef}>
              {!isPhone && <div />}
              <div />
              {remainingInView && <Values />}
              {!isPhone && <div />}
              {!isPhone && <div />}
              {remainingInView && (
                <>
                  <FAQ faqs={faqs} />
                  <CTA />
                </>
              )}
              <div />
            </LandingGradientWrapper>
          </Wrapper>
        </PageContentWrapper>
      </PageContent>
    </Wrapper>
  )
}
